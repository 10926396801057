body {
  margin: 0;
  font-family: 'Merriweather', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .resultsTable{
  border: 1px solid black;
} */

.resultsTable {
  margin-left: -200px;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.resultsTable td, .resultsTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.resultsTable tr:nth-child(even){background-color: #f2f2f2;}

.resultsTable tr:hover {background-color: #ddd;}

.resultsTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3e4140;
  color: white;
}

.checkboxInline{
  display: inline-flex;
}

#finished-label{
  width: 384px;
}

#finished{
  margin-left: -230px;
  height: 20px;
  width: 20px;
}